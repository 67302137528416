import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Container,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
} from '@mui/material';
import * as Sentry from "@sentry/react";

import { clientService } from '../_services';
import toast from "react-hot-toast";

const client_status = [
  {
    value: 'pending',
    label: 'Pending',
  },
  {
    value: 'approved',
    label: 'Approved',
  },
  {
    value: 'rejected',
    label: 'Rejected',
  },
  {
    value: 'suspend',
    label: 'Suspend',
  },
];

function ClientDetail(props) {
    let { id } = props.match.params

    const [clientData, setClientData] = useState();
    const [loading, setLoading] = useState(true);
    const [registering, setRegistering] = useState(false)

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setClientData({ ...clientData, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setRegistering(true)
        try {
            let {users_permissions_user, createdAt, updatedAt, ...data} = clientData

            await clientService.update(data)
            toast.success(`Update successful`, {
                duration: 5000,
            });
        } catch(e) {
            console.error("error in update client", e)
            toast.error(`Update failure`, {
                duration: 5000,
            });
            Sentry.captureException(e);
        } finally {
            setRegistering(false)
        }
    };

    const fetchClient = async () => {
        let clientData = await clientService.getById(id)
        setClientData(clientData)
        setLoading(false)
    }

    useEffect(() => {
        fetchClient()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if(loading) {
        return (<div className="container vh-100 overflow-auto pt-5">
            Loading...
        </div>)
    }

    return (
        <Container className="vh-100 overflow-auto pt-5">
          <Typography variant="h5">Client #{clientData.id}</Typography>
          <hr />
        <div style={{ maxWidth: 600 }}>
            <form onSubmit={handleSubmit}>
            <TextField
                label="Email"
                type="email"
                fullWidth
                variant="outlined"
                value={clientData.users_permissions_user?.email}
                readOnly
                sx={{marginTop: 5}}
            />
            <TextField
                label="Username"
                type="text"
                fullWidth
                variant="outlined"
                value={clientData.users_permissions_user?.username}
                readOnly
                sx={{marginTop: 5}}
            />

            {/* Rest of the form fields */}
            <TextField
                label="First Name"
                type="text"
                fullWidth
                variant="outlined"
                name="first_name"
                value={clientData.first_name}
                onChange={handleInputChange}
                sx={{marginTop: 5}}
                required
            />
            <TextField
                label="Last Name"
                type="text"
                fullWidth
                variant="outlined"
                name="last_name"
                value={clientData.last_name}
                onChange={handleInputChange}
                sx={{marginTop: 5}}
                required
            />
            <TextField
                label="Company Name"
                type="text"
                fullWidth
                variant="outlined"
                name="company_name"
                value={clientData.company_name}
                onChange={handleInputChange}
                sx={{marginTop: 5}}
                required
            />
            <TextField
                label="Company Website"
                type="text"
                fullWidth
                variant="outlined"
                name="company_website"
                value={clientData.company_website}
                sx={{marginTop: 5}}
                onChange={handleInputChange}
            />
            <TextField
                label="Contact"
                type="text"
                fullWidth
                variant="outlined"
                name="contact"
                value={clientData.contact}
                onChange={handleInputChange}
                sx={{marginTop: 5}}
                required
            />
            <FormControl fullWidth variant="outlined"
                                sx={{marginTop: 5}}
            >
                <InputLabel>Status</InputLabel>
                <Select
                name="status"
                value={clientData.status}
                onChange={handleInputChange}
                >
                {client_status.map((o) => (
                    <MenuItem key={o.value} value={o.value}>
                    {o.label}
                    </MenuItem>
                ))}
                </Select>
            </FormControl>
            <TextField
                label="Additional Note"
                placeholder="Additional Note"
                multiline
                rows={4}
                type="text"
                fullWidth
                variant="outlined"
                name="additional_note"
                value={clientData.additional_note}
                onChange={handleInputChange}
                required
                sx={{marginTop: 5}}
            />
            <hr />
            <div>
                <Button
                variant="contained"
                color="primary"
                disabled={registering}
                type="submit"
                sx={{marginRight: 5}}
                >
                {registering && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                )}
                Update a Client
                </Button>
                <Button
                component={Link}
                variant="contained"
                color="secondary"
                to="/clients"
                >
                Back
                </Button>
            </div>
            </form>
        </div>
        </Container>
    );
}

export { ClientDetail };
