export function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.jwt && user.client.api_key) {
        return { 
            'Authorization': 'Bearer ' + user.jwt,
            'onekin-api-key': user.client.api_key,
        };
    } else {
        return {};
    }
}