import { authAdminHeader } from "../_helpers"
import { handleResponse } from "./middleware"
import qs from "qs"

export const clientService = {
  getAll,
  getPendings,
  getSummary,
  getById,
  update,
  approve,
  reject,
  delete: _delete,
}

function getAll(pageSize = 50, page = 1, searchStr) {
  const requestOptions = {
    method: "GET",
    headers: { ...authAdminHeader(), "Content-Type": "application/json" },
  }

  const query = qs.stringify(
    {
      sort: ["createdAt:desc"],
      pagination: {
        page,
        pageSize,
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  )

  return fetch(
    `${process.env.REACT_APP_API_URL}/clients?${query}&filters[$or][0][first_name][$containsi]=${searchStr}&filters[$or][1][last_name][$containsi]=${searchStr}`,
    requestOptions
  ).then(handleResponse)
}

function getPendings() {
  const requestOptions = {
    method: "GET",
    headers: { ...authAdminHeader(), "Content-Type": "application/json" },
  }

  const query = qs.stringify(
    {
      sort: ["id:asc"],
      filters: {
        status: {
          $eq: "pending",
        },
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  )

  return fetch(
    `${process.env.REACT_APP_API_URL}/clients?${query}`,
    requestOptions
  ).then(handleResponse)
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authAdminHeader(), "Content-Type": "application/json" },
  }

  return fetch(
    `${process.env.REACT_APP_API_URL}/clients/${id}`,
    requestOptions
  ).then(handleResponse)
}

function getSummary() {
  const requestOptions = {
    method: "POST",
    headers: { ...authAdminHeader(), "Content-Type": "application/json" },
  }

  return fetch(
    `${process.env.REACT_APP_API_URL}/clients/getClientsSummary`,
    requestOptions
  ).then(handleResponse)
}

function update(client) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authAdminHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      data: client,
    }),
  }

  return fetch(
    `${process.env.REACT_APP_API_URL}/clients/${client.id}`,
    requestOptions
  ).then(handleResponse)
}

function approve(clientId) {
  const requestOptions = {
    method: "POST",
    headers: { ...authAdminHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ clientId }),
  }

  return fetch(
    `${process.env.REACT_APP_API_URL}/clients/approveClient`,
    requestOptions
  ).then(handleResponse)
}
function reject(clientId) {
  const requestOptions = {
    method: "POST",
    headers: { ...authAdminHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ clientId }),
  }

  return fetch(
    `${process.env.REACT_APP_API_URL}/clients/rejectClient`,
    requestOptions
  ).then(handleResponse)
}

function _delete(clientId) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authAdminHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({}),
  }

  return fetch(
    `${process.env.REACT_APP_API_URL}/clients/${clientId}`,
    requestOptions
  ).then(handleResponse)
}
