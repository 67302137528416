import {useState, useEffect} from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import Button from '@mui/material/Button';
import { collectibleService } from 'src/_services';
import Papa from 'papaparse';
import {saveAs} from 'file-saver'
 

const CsvDownloader = ({id})=>{
    const [availableCount, setAvailableCount ] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const [codes, setCodes] = useState([])
    
    useEffect(()=>{
        const getData = async ()=>{
            const codes = await collectibleService.getCodes(id)
            const availableCodes = codes.filter((code)=>code.available)
            setTotalCount(codes.length)
            setAvailableCount(availableCodes.length)
            setCodes(codes)
        }
        getData()
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    const exportCSV = async ()=>{
        if(codes.length > 0){
            const csv = Papa.unparse(codes);
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
            saveAs(blob, 'data.csv');        
        }
    }

    return(
        <Container>
            <Box sx={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                <Title>Collectible Codes</Title>
                <Description>
                    These are the codes that will be used to redeem your collectible
                </Description>
                
                <Box sx={{width:'100%',marginTop:7}}>
                    <Progress>Claimed: {availableCount}/{totalCount}</Progress>
                    {
                        availableCount > 0 &&
                        <StyledLinearProgress variant="determinate" value={availableCount * 100 / totalCount} />
                    }
                </Box>
            </Box>
            <Box sx={{marginTop:5}}>
                <ExportButtton onClick={()=>{exportCSV()}}>
                    EXPORT TO CSV
                </ExportButtton>
            </Box>
        </Container>
    )
}

export default CsvDownloader

const ExportButtton = styled(Button)`
    background-color: transparent;
    border:1px solid #666cff;
    font-size: 12px;
`

const Progress = styled('div')`
    font-size: 16px;
    color: white;
    margin-bottom:10px;

`
const StyledLinearProgress = styled(LinearProgress)`
    background-color: #c2c4ff;
    &.MuiLinearProgress-colorPrimary {
        background-color: #c2c4ff;
    }
`
const Container = styled(`div`)`
    background-color: #101121;
    padding: 25px 25px;
    border-radius: 15px;

`

const Title = styled('div')`
    font-size: 16px;
    font-weight: 500;
`

const Description = styled('div')`
    font-size: 14px;
    font-weight: 300;
    margin-top: 15px;
`
