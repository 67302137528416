import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { clientService } from "../_services";
import EmptyState from "src/_components/EmptyState";
import moment from "moment";
import {
  SuccessButton,
  RejectButton,
  StyledTableRow,
  StyledTableCell,
} from "src/_components/PageElements";
import StatusLabel from "src/_components/StatusLabel";
import Paginator from "src/_components/Paginator";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Divider,
  Paper,
  Box,
  Card,
  TextField,
} from "@mui/material";
import toast from "react-hot-toast";
import SearchIcon from "@mui/icons-material/Search";


function Clients() {
  const [searchStr, setSearchStr] = useState("");
  const [clients, setClients] = useState([]);
  const [summary, setSummary] = useState({
    pending: 0,
    approved: 0,
    rejected: 0,
  });
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    pageCount: 0,
    total: 0,
  });

  const searchClients = (keyword) => {
    fetchClients(1, pagination.pageSize, keyword);
  };

  useEffect(() => {
    fetchClients(1);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchClients = async (page, pageSize, searchKey) => {
    let clients = await clientService.getAll(
      pageSize || pagination.pageSize,
      page,
      searchKey || ""
    );
    console.log("clients", clients);
    setPagination(clients.meta.pagination);
    setClients(
      clients.data.map((o) => {
        return {
          id: o.id,
          ...o.attributes,
        };
      })
    );

    let summary = await clientService.getSummary();
    console.log("summary", summary);

    let pendingStatus = summary.find((o) => o.status === "pending");
    let approvedStatus = summary.find((o) => o.status === "approved");
    let rejectedStatus = summary.find((o) => o.status === "rejected");
    setSummary({
      pending: Number(pendingStatus?.cnt || 0),
      approved: Number(approvedStatus?.cnt || 0),
      rejected: Number(rejectedStatus?.cnt || 0),
    });
  };

  const handleApprove = async (clientId) => {
    console.log(`Approved client with ID: ${clientId}`);
    await clientService.approve(clientId);
    toast.success(`Successfully approved`, {
      duration: 5000,
    });
    fetchClients(pagination.page);
  };

  const handleReject = async (clientId) => {
    console.log(`Reject client with ID: ${clientId}`);
    await clientService.reject(clientId);
    toast.success(`Successfully rejected`, {
      duration: 5000,
    });
    fetchClients(pagination.page);
  };

  const onSelectPage = (page) => {
    setPagination({
      ...pagination,
      page,
    });

    fetchClients(page);
  };
  const onSizeChange = (pageSize) => {
    setPagination({
      ...pagination,
      pageSize,
    });

    fetchClients(pagination.page, pageSize);
  };

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      searchClients(searchStr);
    }, 300);

    return () => clearTimeout(debounceTimeout);
  }, [searchStr]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDynamicSearch = (e) => {
    const keyword = e.target.value;
    setSearchStr(keyword);
  };

  return (
    <Container
      sx={{
        paddingBottom: 20,
        color: "white",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h4" gutterBottom>
          BP Users
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            label="Search"
            type="text"
            fullWidth
            variant="outlined"
            value={searchStr}
            onChange={handleDynamicSearch}
            InputProps={{
              startAdornment: (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <SearchIcon
                    fontSize="medium"
                    sx={{ mr: 2.5, color: "text.primary" }}
                  />
                </Box>
              ),
            }}
          />
        </Box>
      </Box>

      <Divider sx={{ mb: 2 }} />
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Card sx={{ width: 200, height: 120, padding: 5, mb: 3, mr: 3 }}>
          <Typography variant="body1">Pending Review</Typography>
          <Typography variant="h4" style={{ marginTop: 25 }}>
            {summary.pending}
          </Typography>
        </Card>
        <Card sx={{ width: 200, height: 120, padding: 5, mb: 3, mr: 3 }}>
          <Typography variant="body1">Total Approved</Typography>
          <Typography variant="h4" style={{ marginTop: 25 }}>
            {summary.approved}
          </Typography>
        </Card>
        <Card sx={{ width: 200, height: 120, padding: 5, mb: 3 }}>
          <Typography variant="body1">Total Rejected</Typography>
          <Typography variant="h4" style={{ marginTop: 25 }}>
            {summary.rejected}
          </Typography>
        </Card>
      </Box>
      <Paper>
        {
              clients.length===0 ?
                <EmptyState/>:
                   <Table>
                   <TableHead>
                     <StyledTableRow>
                       <StyledTableCell className="has-right-border">ID</StyledTableCell>
                       <StyledTableCell className="has-right-border">
                         Fullname
                       </StyledTableCell>
                       {/* <TableCell>Email</TableCell> */}
                       <StyledTableCell className="has-right-border">
                         Company
                       </StyledTableCell>
                       <StyledTableCell className="has-right-border">
                         Status
                       </StyledTableCell>
                       <StyledTableCell className="has-right-border">
                         Created At
                       </StyledTableCell>
                       <StyledTableCell className="has-right-border">
                         Action
                       </StyledTableCell>
                     </StyledTableRow>
                   </TableHead>
                   <TableBody>
                     {clients.map((client) => (
                       <TableRow key={client.id} style={{ backgroundColor: "#282b42" }}>
                         <TableCell>{client.id}</TableCell>
                         <TableCell>
                           <Link to={`clients/${client.id}`} style={{ color: "white" }}>
                             {client.first_name} {client.last_name}
                           </Link>
                         </TableCell>
                         {/* <TableCell>{client.email}</TableCell> */}
                         <TableCell>{client.company_name}</TableCell>
                         {/* <TableCell>{client.status.toUpperCase()}</TableCell> */}
                         <TableCell>
                           <StatusLabel value={client.status} />
                         </TableCell>
                         <TableCell>
                           {moment(client.createdAt).format("YYYY-MM-DD")}
                         </TableCell>
                         <TableCell>
                           {client.status === "pending" && (
                             <>
                               <SuccessButton
                                 variant="contained"
                                 size="small"
                                 disabled={client.status !== "pending"}
                                 onClick={() => handleApprove(client.id)}
                               >
                                 Approve
                               </SuccessButton>
                               <RejectButton
                                 variant="contained"
                                 color="error"
                                 size="small"
                                 disabled={client.status !== "pending"}
                                 onClick={() => handleReject(client.id)}
                               >
                                 Reject
                               </RejectButton>
                             </>
                           )}
                         </TableCell>
                       </TableRow>
                     ))}
                   </TableBody>
                 </Table>
              
        }
   
      </Paper>
      {pagination.total > 0 && (
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Box sx={{ margin: 10 }}>
            <Paginator
              count={pagination.pageCount}
              totalCount={pagination.total}
              pageSize={pagination.pageSize}
              currentPage={pagination.page}
              onPageSizeChange={(pageSize) => {
                onSizeChange(pageSize);
              }}
              onChange={(page) => {
                onSelectPage(page);
              }}
            />
          </Box>
        </Box>
      )}
      {/* {clients.length === 0 && <div>No user</div>} */}
    </Container>
  );
}

export { Clients };
