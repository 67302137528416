import React from 'react';
import { useSelector } from 'react-redux';


function HomePage() {
    const user = useSelector(state => state.authentication.user);

    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-12 mt-5">
                    <h2>Welcome, {user.user.username}!</h2>
                    <p>You're logged in</p>
                </div>
            </div>
        </div>
    );
}

export { HomePage };
