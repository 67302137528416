import React, { useEffect, useState, useRef } from 'react';
import { Button, Container, TextField, Typography, Checkbox, FormControlLabel, FormControl, InputLabel, Select, MenuItem, Grid, Card, Box } from '@mui/material';
import { collectibleService, gameService } from '../_services';
import toast from "react-hot-toast";
import CsvDownloader from 'src/_components/CsvDownloader';
import { FeaturedImageUploader } from "../_components/FeaturedImageUploader"

const COLLECTIBLE_STATUS = [
    {
        value: 'Pending',
        label: 'Pending',
    },
    {
        value: 'Approved',
        label: 'Approved',
    },
    {
        value: 'Rejected',
        label: 'Rejected',
    },
    {
        value: 'Live',
        label: 'Live',
    },
    {
        value: 'Paused',
        label: 'Paused',
    },
    {
        value: 'Archived',
        label: 'Archived',
    },
    {
        value: 'Deleted',
        label: 'Deleted',
    },
]

function CollectibleDetail(props) {
    let { id } = props.match.params
    console.log('collectible id', id)

    const [collectibleData, setCollectibleData] = useState();
    const [loading, setLoading] = useState(true);
    const [registering, setRegistering] = useState(false)

    const [faq, setFaq] = useState({
        question: '',
        answer: ''
    });

    const [games, setGames] = useState([]);

    const dragItem = useRef();
    const dragOverItem = useRef();


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setCollectibleData({ ...collectibleData, [name]: value });
    };

    const handleInputCheck = (event) => {
        const { name, checked } = event.target;
        setCollectibleData({ ...collectibleData, [name]:!checked });
    };

    const handleFaqInputChange = (event) => {
        const { name, value } = event.target;
        setFaq({ ...faq, [name]: value });
    };

    const handleAddFaq = () => {
        setCollectibleData({ ...collectibleData, faq_list: [...collectibleData.faq_list, faq] });
        setFaq({ question: '', answer: '' });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(collectibleData);
        setRegistering(true)
        try {
            collectibleData.assets = {
                ...collectibleData.assets,
                featureImage: collectibleData.feature_image,
                cardMedia: {
                    defaultMedia: {
                        src1x: collectibleData.card_media||collectibleData.card_media_large,
                        src2x: collectibleData.card_media_large||collectibleData.card_media,
                    }
                },
                heroMedia: {
                    defaultMedia: {
                        src1x: collectibleData.hero_media||collectibleData.hero_media_large,
                        src2x: collectibleData.hero_media_large||collectibleData.hero_media,
                    }
                },
                thumbnailImage: {
                    defaultMedia: {
                        src1x: collectibleData.thumbnail_image||collectibleData.thumbnail_image_large,
                        src2x: collectibleData.thumbnail_image_large||collectibleData.thumbnail_image,
                    }
                },
            }

            let newCollectibleData = await collectibleService.update(collectibleData)
            console.log('newCollectibleData', newCollectibleData)
            toast.success(`Update successful`, {
                duration: 5000,
            });
        } catch(e) {
            console.log("error", e)
            toast.error(`Update failure`, {
                duration: 5000,
            });
        } finally {
            setRegistering(false)
        }
    };

    const fetchGames = async () => {
        let games = await gameService.getAll()
        games = games.data.map(o=>{
            return {id: o.id, ...o.attributes}
        })
        setGames(games)
        let collectibleData = await collectibleService.getById(id)

        console.log('collectibleData', collectibleData)
        let {assets} = collectibleData
        collectibleData.feature_image = assets?.featureImage
        collectibleData.card_media = assets?.cardMedia?.defaultMedia?.src1x
        collectibleData.card_media_large = assets?.cardMedia?.defaultMedia?.src2x
        collectibleData.hero_media = assets?.heroMedia?.defaultMedia?.src1x
        collectibleData.hero_media_large = assets?.heroMedia?.defaultMedia?.src2x
        collectibleData.thumbnail_image = assets?.thumbnailImage?.defaultMedia?.src1x
        collectibleData.thumbnail_image_large = assets?.thumbnailImage?.defaultMedia?.src2x

        setCollectibleData(collectibleData)
        setLoading(false)
    }

    useEffect(() => {
        fetchGames()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleUpdateFiles = (files) => {
        if (files) {
          setCollectibleData({
            ...collectibleData,
            feature_image: files.find((o) => o.category === "feature").url,
            card_media_large: files.find((o) => o.category === "card_2x").url,
            card_media: files.find((o) => o.category === "card_1x").url,
            hero_media_large: files.find((o) => o.category === "hero_2x").url,
            hero_media: files.find((o) => o.category === "hero_1x").url,
            thumbnail_image_large: files.find((o) => o.category === "thumbnail_2x")
              .url,
            thumbnail_image: files.find((o) => o.category === "thumbnail_1x").url,
            detail_image_large: files.find((o) => o.category === "detail_2x").url,
            detail_image: files.find((o) => o.category === "detail_1x").url,
          })
        } else {
          setCollectibleData({
            ...collectibleData,
            feature_image: "",
            card_media_large: "",
            card_media: "",
            hero_media_large: "",
            hero_media: "",
            thumbnail_image_large: "",
            thumbnail_image: "",
            detail_image_large: "",
            detail_image: "",
          })
        }
    }
    const deleteFaq = (e, index) => {
        if(e) {
            e.stopPropagation();
            e.preventDefault()
        }
        collectibleData.faq_list.splice(index, 1)
        setCollectibleData({ ...collectibleData, faq_list: [...collectibleData.faq_list] });
    };
    
    const dragStart = (_, position) => {
        dragItem.current = position;
    };
     
    const dragEnter = (_, position) => {
        dragOverItem.current = position;
    };
    
    const drop = () => {
        const copyListItems = [...collectibleData.faq_list];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        setCollectibleData({ ...collectibleData, faq_list: copyListItems });
    };

    if(loading) {
        return (<div className="container vh-100 overflow-auto pt-5">
            Loading...
        </div>)
    }

    return (
        <Container className="vh-100 overflow-auto pt-5">
          <Typography variant="h2">{collectibleData.title}</Typography>
          <hr/>
          <Grid container>
            <Grid item lg={7}>
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Game Name</InputLabel>
                    <Select label="Game Name" name="game_id" value={collectibleData.game?.id} onChange={handleInputChange} required>
                        {games.map(game=><MenuItem key={game.id} value={game.id}>{game.title}</MenuItem>)}
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <TextField label="Collectible Name" type="text" name="title" value={collectibleData.title} onChange={handleInputChange} required />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <TextField label="About your Collectible" multiline rows={3} type="text" name="description" value={collectibleData.description} onChange={handleInputChange} required />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <TextField label="Claim Instructions" multiline rows={3} type="text" name="claim_instructions" value={collectibleData.claim_instructions} onChange={handleInputChange} required />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <TextField label="What's Included in the Claim (1Kin Exclusive)" multiline rows={5} type="text" name="item_details" value={collectibleData.item_details?.join("\n")} onChange={handleInputChange} />
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <TextField label="Short Description" type="text" name="short_description" value={collectibleData.short_description} onChange={handleInputChange} />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <TextField label="Claim Cap" type="number" name="total_count" value={collectibleData.total_count} onChange={handleInputChange} />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <TextField label="Start Date" type="date" name="start_date" value={collectibleData.start_date} onChange={handleInputChange} />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <TextField label="End Date" type="date" name="end_date" value={collectibleData.end_date} onChange={handleInputChange} />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Status</InputLabel>
                    <Select label="Status" name="status" value={collectibleData.status} onChange={handleInputChange} >
                        {COLLECTIBLE_STATUS.map(o=><MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>)}
                    </Select>
                </FormControl>

                <InputLabel style={{marginTop: 5}}>FAQs</InputLabel>
                <FormControl fullWidth>
                  {collectibleData.faq_list.length > 0 && (
                    <div style={{marginTop:5}}>
                      {collectibleData.faq_list.map((faq, index) => (
                        <Card
                            key={index}
                            sx={{padding:5, marginBottom: 5, position:'relative'}}
                            style={{ cursor: 'pointer' }}
                            onDragStart={(e) => dragStart(e, index)}
                            onDragEnter={(e) => dragEnter(e, index)}
                            onDragEnd={drop}
                            draggable
                        >
                            <button className='close-btn' onClick={(e) => deleteFaq(e, index)}>
                            &times;
                            </button>
                            <div className="font-weight-bold">{faq.question}</div>
                            <div>{faq.answer}</div>
                        </Card>
                      ))}
                    </div>
                  )}
                  <FormControl fullWidth>
                    <TextField label="Question" type="text" name="question" value={faq.question} onChange={handleFaqInputChange} />
                  </FormControl>
                  
                  <FormControl fullWidth margin="normal">
                    <TextField
                        multiline
                        rows={3}
                        name="answer"
                        placeholder="Answer"
                        value={faq.answer}
                        onChange={handleFaqInputChange}
                    />
                  </FormControl>
                  <Button variant="contained" color="primary" onClick={handleAddFaq}>
                    Add FAQ
                  </Button>
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <TextField label="Game Collectible ID" type="text" name="game_collectible_id" value={collectibleData.collectible_uid} onChange={handleInputChange} />
                </FormControl>

                <InputLabel style={{marginTop:5}}>Feature Image</InputLabel>
                <FeaturedImageUploader
                    value={collectibleData.feature_image||collectibleData.hero_media||collectibleData.hero_media_large}
                    onChangeFile={(files) => {
                    handleUpdateFiles(files)
                    }}
                />

                <FormControl fullWidth margin='normal'>
                    <FormControlLabel
                        label="Will this claim be auto-completed?"
                        control={
                            <Checkbox
                            checked={!collectibleData.require_complete_by_client}
                            onChange={handleInputCheck}
                            name="require_complete_by_client"
                            />
                        }   
                    />
                </FormControl>

                <hr/>
                <FormControl fullWidth margin="normal">
                  <Button variant="contained" color="primary" type="submit">
                    {registering && <span className="spinner-border spinner-border-sm mr-1"></span>}
                    Update a Collectible
                  </Button>
                </FormControl>
              </form>
            </Grid>
            <Grid item lg={5}>
                <Box sx={{m:4}}>
                    <CsvDownloader id={id}/>
                </Box>
            </Grid>
          </Grid>
        </Container>
    );
}

export { CollectibleDetail };
