import { styled } from '@mui/material/styles'

const colors = {
    "approved":"#69ca2b",
    "pending":"#fdb528",
    "rejected":"#ff4d49",
}

const StatusLabel = ({value})=>{
    return(
        <StatusLabelContainer color={colors[value.toLowerCase()]}>
            {value}
        </StatusLabelContainer>
    )
}
export default StatusLabel

const StatusLabelContainer = styled('div')(({color})=>({
    border:`1px solid ${color}`,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    fontSize:12,
    borderRadius:6,
    color:color,
    textTransform:'capitalize',
    padding:'3px 5px'
}))
  
