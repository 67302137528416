import React, { useEffect, useState } from "react";
import { Box, Button, Container } from "@mui/material";
import {
  DataGrid,
  getGridNumericOperators,
  getGridStringOperators,
  getGridDateOperators,
} from "@mui/x-data-grid";
import { waitlistService } from "../_services";
import { Toolbar } from "src/_components/WaitlistToolbar";
import EmptyState from "src/_components/EmptyState";

const numericOperators = getGridNumericOperators().filter(
  (operator) => operator.value !== "isAnyOf"
);
const stringOperators = getGridStringOperators().filter(
  (operator) => operator.value !== "isAnyOf"
);
const dateOperators = getGridDateOperators().filter(
  (operator) => operator.value !== "not"
);

const columns = [
  {
    field: "id",
    headerName: "User ID",
    type: "number",
    minWidth: 150,
    filterOperators: numericOperators,
  },
  {
    field: "email",
    headerName: "Email",
    minWidth: 200,
    filterOperators: stringOperators,
  },
  {
    field: "fullname",
    headerName: "Name",
    minWidth: 150,
    filterOperators: stringOperators,
  },
  {
    field: "country",
    headerName: "Country",
    minWidth: 150,
    filterOperators: stringOperators,
  },
  {
    field: "state",
    headerName: "State",
    minWidth: 150,
    filterOperators: stringOperators,
  },
  {
    field: "city",
    headerName: "City",
    minWidth: 150,
    filterOperators: stringOperators,
  },
  {
    field: "referred_by",
    headerName: "Referred By",
    minWidth: 150,
    filterOperators: stringOperators,
  },
  {
    field: "referral_code",
    headerName: "Referral Code",
    minWidth: 150,
    filterOperators: stringOperators,
  },
  {
    field: "waitlist_position",
    headerName: "Waitlist Place",
    minWidth: 150,
    filterOperators: numericOperators,
  },
  {
    field: "shares",
    headerName: "Shares",
    minWidth: 150,
    filterOperators: numericOperators,
  },
  {
    field: "instagram_handle",
    headerName: "Instagram Handle",
    minWidth: 150,
    filterOperators: stringOperators,
  },
  {
    field: "twitter_handle",
    headerName: "Twitter Handle",
    minWidth: 150,
    filterOperators: stringOperators,
  },
  {
    field: "twitch_handle",
    headerName: "Twitch Handle",
    minWidth: 150,
    filterOperators: stringOperators,
  },
  {
    field: "discord_handle",
    headerName: "Discord Handle",
    minWidth: 150,
    filterOperators: stringOperators,
  },
  {
    field: "utm_source",
    headerName: "UTM Source",
    minWidth: 150,
    filterOperators: stringOperators,
  },
  {
    field: "utm_medium",
    headerName: "UTM Medium",
    minWidth: 150,
    filterOperators: stringOperators,
  },
  {
    field: "utm_campaign",
    headerName: "UTM Campaign",
    minWidth: 150,
    filterOperators: stringOperators,
  },
  {
    field: "utm_term",
    headerName: "UTM Term",
    minWidth: 150,
    filterOperators: stringOperators,
  },
  {
    field: "utm_content",
    headerName: "UTM Content",
    minWidth: 150,
    filterOperators: stringOperators,
  },
  {
    field: "email_verified",
    headerName: "Email Verified",
    type: "boolean",
    minWidth: 150,
  },
  {
    field: "createdAt",
    headerName: "SignUp Date",
    type: "date",
    minWidth: 200,
    filterOperators: dateOperators,
    valueGetter: (params) => {
      return new Date(params.value);
    },
  },
];

function Waitlist(props) {
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [rows, setRows] = useState([]);
  const [filterModel, setFilterModel] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [sortModel, setSortModel] = useState({ sort: "asc", sortColumn: "id" });
  const [qty, setQty] = useState(0);
  const [selectedIds, setSelectedIds] = React.useState([]);

  useEffect(() => {
    fetchWaitlist(
      filterModel,
      sortModel.sort,
      sortModel.sortColumn,
      paginationModel.page,
      paginationModel.pageSize
    );
  }, [filterModel, sortModel, paginationModel]);

  const fetchWaitlist = async (
    filterModel,
    sort,
    sortColumn,
    page,
    pageSize
  ) => {
    setLoading(true);
    const waitlist = await waitlistService.getAll(
      filterModel,
      sort,
      sortColumn,
      page,
      pageSize
    );
    setTotal(waitlist.meta.pagination.total);
    setRows(
      waitlist.data.map((o) => {
        return {
          id: o.id,
          ...o.attributes,
        };
      })
    );
    setLoading(false);
  };

  const handleFilterModel = (newModel) => {
    setFilterModel(newModel);
  };

  const handleSortModel = (newModel) => {
    if (newModel.length) {
      setSortModel({ sort: newModel[0].sort, sortColumn: newModel[0].field });
    } else {
      setSortModel({ sort: "asc", sortColumn: "id" });
    }
  };

  const handlePaginationModel = (newModel) => {
    setPaginationModel(newModel);
  };

  const admitSelectedUsers = async () => {
    await waitlistService.approve(selectedIds);
    fetchWaitlist(
      filterModel,
      sortModel.sort,
      sortModel.sortColumn,
      paginationModel.page,
      paginationModel.pageSize
    );
  };

  const selectUsers = () => {
    const selectedIds = rows.slice(0, qty).map((item) => item?.id);
    setSelectedIds(selectedIds);
    setQty((selectedIds || []).length);
  };

  return (
    <Container>
      <Box sx={{ mb: 4 }}>
        <DataGrid
          autoHeight
          loading={loading}
          rows={rows}
          columns={columns}
          rowCount={total}
          filterMode="server"
          onFilterModelChange={handleFilterModel}
          pagination
          paginationMode="server"
          pageSizeOptions={[10, 20, 30, 50, 100]}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModel}
          sortingMode="server"
          onSortModelChange={handleSortModel}
          components={{ Toolbar: Toolbar }}
          componentsProps={{
            toolbar: {
              total,
              qty,
              setQty,
              selectUsers,
            },
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                state: false,
                city: false,
                referred_by: false,
                referral_code: false,
                waitlist_position: false,
                shares: false,
                instagram_handle: false,
                twitter_handle: false,
                twitch_handle: false,
                discord_handle: false,
                utm_source: false,
                utm_medium: false,
                utm_campaign: false,
                utm_term: false,
                utm_content: false,
              },
            },
          }}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              borderRadius: "0px !important",
            },
            "& .MuiDataGrid-virtualScroller": {
              marginTop: "0px !important",
            },
          }}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(newSelectedIds) => {
            setSelectedIds(newSelectedIds);
          }}
          rowSelectionModel={selectedIds}
        />
        {
          rows.length === 0  && !loading && <EmptyState/>
        }
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", py: 3 }}>
        <Button variant="contained" onClick={admitSelectedUsers}>
          Admit Selected Users
        </Button>
      </Box>
    </Container>
  );
}

export { Waitlist };
