import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { userActions } from '../_actions';

function AuthCallback(props) {
    const code = new URLSearchParams(props.location.search).get("code")
    
    const [loading] = useState(true);
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(()=>{
        if(code) {
            authWithCode(code)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    async function authWithCode(code) {
        console.log('code', code)
        const { from } = location.state || { from: { pathname: "/" } };
        dispatch(userActions.googleLogin(code, from));
        // let user = await userService.googleAuthWithCode(code)
        // console.log('user', user)
    }

    return (
        <div className="container-full d-flex align-items-center" style={{height: '100vh', backgroundColor: 'rgb(48, 51, 78)', color: 'white', padding: '100px 0'}}>
            <div style={{maxWidth: 800, margin: '0 auto'}}>
                <div className="spinner-border text-light" role="status">
                    {loading && <span className="sr-only">Loading...</span>}
                </div>
            </div>
        </div>
    );
}

export { AuthCallback };
