import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, TextField, Typography, Checkbox, FormControlLabel, FormControl, InputLabel, Grid, Card } from '@mui/material';

import { gameService } from '../_services';
import { FileUploader } from '../_components/FileUploader';
import toast from "react-hot-toast";

const platforms = ['PC', 'Web Browser', 'Android', 'iOS', 'Xbox', 'Playstation', 'Steam', 'Epic Game Store'];
const gameModes = ['Single Player', 'Multiple Player', 'Massively Multiple Online'];
const genres = ['RPG', 'Platformer', 'Puzzles', 'Casual', 'Indie', 'Action', 'FPS', 'Arcade'];

function GameDetail(props) {
    let { id } = props.match.params
    console.log('game id', id)

    const [gameData, setGameData] = useState();
    const [loading, setLoading] = useState(true);
    const [registering, setRegistering] = useState(false)

    const [faq, setFaq] = useState({
        question: '',
        answer: ''
    });

    const dragItem = useRef();
    const dragOverItem = useRef();

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setGameData({ ...gameData, [name]: value });
    };

    const handlePlatformChange = (event) => {
        const { name, checked } = event.target;
        if (checked) {
            setGameData({ ...gameData, platforms: [...gameData.platforms, name] });
        } else {
            const index = gameData.platforms.indexOf(name);
            const newPlatforms = gameData.platforms;
            newPlatforms.splice(index, 1);
            setGameData({ ...gameData, platforms: newPlatforms });
        }
    };

    const handleGenreChange = (event) => {
        const { name, checked } = event.target;
        if (checked) {
            setGameData({ ...gameData, genres: [...gameData.genres, name] });
        } else {
            const index = gameData.genres.indexOf(name);
            const newGenres = gameData.genres;
            newGenres.splice(index, 1);
            setGameData({ ...gameData, genres: newGenres });
        }
    };
    const handleGameModeChange = (event) => {
        const { name, checked } = event.target;
        if (checked) {
            setGameData({ ...gameData, game_modes: [...gameData.game_modes, name] });
        } else {
            const index = gameData.game_modes.indexOf(name);
            const newGameModes = gameData.game_modes;
            newGameModes.splice(index, 1);
            setGameData({ ...gameData, game_modes: newGameModes });
        }
    };

    const handleFaqInputChange = (event) => {
        const { name, value } = event.target;
        setFaq({ ...faq, [name]: value });
    };

    const handleAddFaq = () => {
        setGameData({ ...gameData, faq_list: [...gameData.faq_list, faq] });
        setFaq({ question: '', answer: '' });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(gameData);
        setRegistering(true)
        try {
            let updateGameData = {...gameData}
            if(updateGameData.cover_image||updateGameData.cover_image_large) {
                updateGameData.cover_art = {
                    defaultMedia: {
                        src1x: updateGameData.cover_image||updateGameData.cover_image_large,
                        src2x: updateGameData.cover_image_large||updateGameData.cover_image,
                    }
                }
            }
            if(updateGameData.vendor_icon||updateGameData.vendor_icon_large) {
                updateGameData.vendor_icon = {
                    defaultMedia: {
                        src1x: updateGameData.vendor_icon||updateGameData.vendor_icon_large,
                        src2x: updateGameData.vendor_icon_large||updateGameData.vendor_icon,
                    }
                }
            }
            if(updateGameData.game_link_url) {
                updateGameData.account_link_config = {
                    ...updateGameData.account_link_config,
                    linkingUrl: gameData.game_link_url
                }
            }

            let newGameData = await gameService.update(updateGameData)
            console.log('newGameData', newGameData)
            toast.success(`Update successful`, {
                duration: 5000,
            });
        } catch(e) {
            console.log("error", e)
            toast.error(`Update failure`, {
                duration: 5000,
            });
        } finally {
            setRegistering(false)
        }
    };

    useEffect(() => {
        fetchGame()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchGame = async () => {
        let gameData = await gameService.getById(id)
        console.log('gameData', gameData)
        gameData = {id: gameData.data.id, ...gameData.data.attributes}
        let {cover_art, vendor_icon, account_link_config} = gameData
        gameData.cover_image = cover_art?.defaultMedia?.src1x
        gameData.cover_image_large = cover_art?.defaultMedia?.src2x
        gameData.vendor_icon = vendor_icon?.defaultMedia?.src1x
        gameData.vendor_icon_large = vendor_icon?.defaultMedia?.src2x
        gameData.game_link_url = account_link_config?.linkingUrl
        setGameData(gameData)
        setLoading(false)
    }

    const handleUpdateFile = (file, name) => {
        setGameData({ ...gameData, [name]: file });
    }

    const deleteFaq = (e, index) => {
        if(e) {
            e.stopPropagation();
            e.preventDefault()
        }
        gameData.faq_list.splice(index, 1)
        setGameData({ ...gameData, faq_list: [...gameData.faq_list] });
    };

    const dragStart = (_, position) => {
        dragItem.current = position;
    };
     
    const dragEnter = (_, position) => {
        dragOverItem.current = position;
    };
    
    const drop = () => {
        const copyListItems = [...gameData.faq_list];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        setGameData({ ...gameData, faq_list: copyListItems });
    };

    if(loading) {
        return (<div className="container vh-100 overflow-auto pt-5">
            Loading...
        </div>)
    }

    return (
        <Container className="vh-100 overflow-auto pt-5">
          <Typography variant="h2">{gameData.title}</Typography>
          <hr/>
          <Grid container>
            <Grid item lg={8}>
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth margin="normal">
                  <TextField label="Game Name" type="text" name="title" value={gameData.title} onChange={handleInputChange} required />
                </FormControl>
    
                <FormControl fullWidth margin="normal">
                  <TextField multiline label="Description" rows={3} name="description" value={gameData.description} onChange={handleInputChange} required />
                </FormControl>
    
                <InputLabel sx={{marginTop: 5}}>Platforms</InputLabel>
                <FormControl fullWidth>
                  <Grid container>
                    {platforms.map((platform, index) => (
                      <Grid item key={index} xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={gameData.platforms.includes(platform)}
                              onChange={handlePlatformChange}
                              name={platform}
                            />
                          }
                          label={platform}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </FormControl>
    
                <InputLabel sx={{marginTop: 5}}>Genres</InputLabel>
                <FormControl fullWidth>
                  <Grid container>
                    {genres.map((item, index) => (
                      <Grid item key={index} xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={gameData.genres.includes(item)}
                              onChange={handleGenreChange}
                              name={item}
                            />
                          }
                          label={item}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </FormControl>
    
                <InputLabel sx={{marginTop:5}}>Game Modes</InputLabel>
                <FormControl fullWidth>
                  <Grid container>
                    {gameModes.map((item, index) => (
                      <Grid item key={index} xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={gameData.game_modes.includes(item)}
                              onChange={handleGameModeChange}
                              name={item}
                            />
                          }
                          label={item}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </FormControl>
    
                <FormControl fullWidth margin="normal">
                  <TextField label="Developer / Publisher" type="text" name="publisher" value={gameData.publisher} onChange={handleInputChange} />
                </FormControl>
    
                <FormControl fullWidth margin="normal">
                  <TextField label="Min Age" type="number" name="min_age" value={gameData.min_age} onChange={handleInputChange} />
                </FormControl>
    
                <FormControl fullWidth margin="normal">
                  <TextField label="Release Date" type="date" name="release_date" value={gameData.release_date} onChange={handleInputChange} />
                </FormControl>
    
                <InputLabel style={{marginTop: 5}}>FAQs</InputLabel>
                <FormControl fullWidth>
                  {gameData.faq_list.length > 0 && (
                    <div style={{marginTop:5}}>
                      {gameData.faq_list.map((faq, index) => (
                        <Card
                            key={index}
                            sx={{padding:5, marginBottom: 5, position:'relative'}}
                            style={{ cursor: 'pointer' }}
                            onDragStart={(e) => dragStart(e, index)}
                            onDragEnter={(e) => dragEnter(e, index)}
                            onDragEnd={drop}
                            draggable
                        >
                            <button className='close-btn' onClick={(e) => deleteFaq(e, index)}>
                            &times;
                            </button>
                            <div className="font-weight-bold">{faq.question}</div>
                            <div>{faq.answer}</div>
                        </Card>
                      ))}
                    </div>
                  )}
                  <FormControl fullWidth>
                    <TextField label="Question" type="text" name="question" value={faq.question} onChange={handleFaqInputChange} />
                  </FormControl>
                  
                  <FormControl fullWidth margin="normal">
                    <TextField
                        multiline
                        rows={3}
                        name="answer"
                        placeholder="Answer"
                        value={faq.answer}
                        onChange={handleFaqInputChange}
                    />
                  </FormControl>
                  <Button variant="contained" color="primary" onClick={handleAddFaq}>
                    Add FAQ
                  </Button>
                </FormControl>
    
                <FormControl fullWidth margin="normal">
                  <TextField label="Official Website URL" type="url" name="official_website" value={gameData.official_website} onChange={handleInputChange} />
                </FormControl>
    
                <FormControl fullWidth margin="normal">
                  <TextField label="Official Secure E-mail" type="text" name="official_email" value={gameData.official_email} onChange={handleInputChange} />
                </FormControl>
    
                <FormControl fullWidth margin="normal">
                  <TextField label="Your Name (Person Submitting Form)" type="text" name="person_name" value={gameData.person_name} onChange={handleInputChange} />
                </FormControl>
    
                <FormControl fullWidth margin="normal">
                  <TextField label="Game Link URL" type="url" name="game_link_url" value={gameData.game_link_url} onChange={handleInputChange} />
                </FormControl>
    
                <InputLabel style={{marginTop:5}}>Cover Image Large</InputLabel>
                <FormControl fullWidth>
                  <FileUploader value={gameData.cover_image_large} onChangeFile={(file) => { handleUpdateFile(file, 'cover_image_large') }} />
                </FormControl>
    
                <InputLabel style={{marginTop:5}}>Cover Image</InputLabel>
                <FormControl fullWidth>
                    <FileUploader value={gameData.cover_image} onChangeFile={(file) => { handleUpdateFile(file, 'cover_image') }} />
                </FormControl>
    
                <InputLabel style={{marginTop:5}}>Vendor Icon Large</InputLabel>
                <FormControl fullWidth>
                  <FileUploader value={gameData.vendor_icon_large} onChangeFile={(file) => { handleUpdateFile(file, 'vendor_icon_large') }} />
                </FormControl>
    
                <InputLabel style={{marginTop:5}}>Vendor Icon</InputLabel>
                <FormControl fullWidth>
                  <FileUploader value={gameData.vendor_icon} onChangeFile={(file) => { handleUpdateFile(file, 'vendor_icon') }} />
                </FormControl>
    
                <hr/>
    
                <FormControl fullWidth margin="normal">
                  <Button variant="contained" color="primary" type="submit" disabled={registering}>
                    {registering && <span className="spinner-border spinner-border-sm mr-1"></span>}
                    Update a Game
                  </Button>
                </FormControl>
              </form>
            </Grid>
          </Grid>
        </Container>
    );
}

export { GameDetail };
