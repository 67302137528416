import React, { useEffect } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { history } from '../_helpers';
import { alertActions } from '../_actions';
import { PrivateRoute } from '../_components';
import { HomePage } from '../HomePage';
import { LoginPage } from '../LoginPage';
import { AllGames } from '../MyGames';
import { GameDetail } from '../GameDetail';
import { CollectibleDetail } from '../CollectibleDetail';
import { Clients } from '../Clients';
import { ClientDetail } from '../ClientDetail';
import { AllCollectibles } from '../Collectibles';
import { AuthCallback } from '../LoginPage/AuthCallback';
import { Waitlist } from 'src/Waitlist';
import { Users } from 'src/Users';
import { Toaster } from "react-hot-toast";

function App() {
    const alert = useSelector(state => state.alert);
    const dispatch = useDispatch();

    useEffect(() => {
        history.listen(() => {
            // clear alert on location change
            dispatch(alertActions.clear());
        });
    }, [dispatch]);

    useEffect(()=>{
        if(alert.message) {
            setTimeout(()=>{
                dispatch(alertActions.clear());
            }, 5000)
        }
    }, [alert.message, dispatch])

    return (
        <div>            
            {alert.message &&
                <div className={`alert ${alert.type}`} style={{position:'absolute', left:0, right:0, top:0, zIndex: 100}}>
                    {alert.message}
                    {/* <span class="cursor">close</span> */}
                </div>
            }
            <Router history={history}>
                <Switch>
                    <PrivateRoute exact path="/" component={HomePage} />
                    <PrivateRoute exact path="/clients" component={Clients} />
                    <PrivateRoute exact path="/clients/:id" component={ClientDetail} />
                    <PrivateRoute exact path="/waitlist" component={Waitlist} />
                    <PrivateRoute exact path="/users" component={Users} />
                    <PrivateRoute exact path="/games" component={AllGames} />
                    <PrivateRoute exact path="/games/:id" component={GameDetail} />
                    <PrivateRoute exact path="/collectibles" component={AllCollectibles} />
                    <PrivateRoute exact path="/collectibles/:id" component={CollectibleDetail} />
                    <Route path="/login" component={LoginPage} />
                    <Route path="/auth/callback" component={AuthCallback} />
                    <Redirect from="*" to="/" />
                </Switch>
            </Router>
            <Toaster />
        </div>
    );
}

export { App };
