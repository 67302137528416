import axios from 'axios';
import { authHeader, authAdminHeader } from '../_helpers';
import qs from "qs";
import moment from "moment";
import { handleResponse } from "./middleware"

export const userService = {
    login,
    logout,
    register,
    getAll,
    getById,
    update,
    delete: _delete,
    upload,
    uploadDirect,
    googleAuthInit,
    googleAuthWithCode,
    collectibleImageUpload,
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ identifier: username, password })
    };

    return fetch(`${process.env.REACT_APP_API_URL}/auth/local/loginAdmin`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));

            return user;
        });
}

async function upload(file, uploadConfig) {
    console.log('file', file)
    const formData = new FormData()
    formData.append('files', file)

    return axios.post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
        ...uploadConfig,
    // return axios.post(`http://localhost:1337/v1/upload`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json'
        }
    })
    .then(result => {
        return result.data
    })
}

async function uploadDirect(file, uploadConfig) {
    //get pre-sign url
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ fileName: file.name, contentType: file.type })
    };

    return fetch(`${process.env.REACT_APP_API_URL}/utils/getUploadURL`, requestOptions)
        .then(handleResponse)
        .then(ret => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            console.log('ret', ret)
            let {uploadURL} = ret

            //upload to aws
            return axios.put(uploadURL, file, {
                ...uploadConfig,
                headers: {
                    'Content-Type': file.type,
                },
            }).then(ret=>{
                console.log(ret)
                if(ret.status === 200) {
                    return [{
                        url: uploadURL.substr(0, uploadURL.indexOf("?"))
                    }]
                }
            })
        })
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function getAll(filterModel, sort, sortColumn, page, pageSize) {
    let filters = {};
    if (filterModel && filterModel.items && filterModel.items.length) {
        if (filterModel.items[0].field === "email_verified") {
            if (filterModel.items[0].operator === "is") {
                if (filterModel.items[0].value === "true") {
                    filters = { email_verified: { $eq: true } };
                } else if (filterModel.items[0].value === "false") {
                    filters = {
                        $or: [
                            { email_verified: { $eq: false } },
                            { email_verified: { $null: true } },
                        ],
                    };
                } else {
                    filters = {};
                }
            }
        } else if (filterModel.items[0].value) {
            if (filterModel.items[0].field === "createdAt") {
                const currentDateStart = moment(filterModel.items[0].value)
                    .startOf("day")
                    .toDate()
                    .toISOString();
                const currentDateEnd = moment(filterModel.items[0].value)
                    .endOf("day")
                    .toDate()
                    .toISOString();

                switch (filterModel.items[0].operator) {
                    case "is":
                        filters = {
                            createdAt: { $gte: currentDateStart, $lte: currentDateEnd },
                        };
                        break;
                    case "after":
                        filters = { createdAt: { $gte: currentDateEnd } };
                        break;
                    case "onOrAfter":
                        filters = { createdAt: { $gte: currentDateStart } };
                        break;
                    case "before":
                        filters = { createdAt: { $lte: currentDateStart } };
                        break;
                    case "onOrBefore":
                        filters = { createdAt: { $lte: currentDateEnd } };
                        break;
                    default:
                        break;
                }
            } else {
                const operators = {
                    "=": "$eq",
                    "!=": "$ne",
                    ">": "$gt",
                    ">=": "$gte",
                    "<": "$lt",
                    "<=": "$lte",
                    contains: "$containsi",
                    equals: "$eqi",
                    startsWith: "$startsWith",
                    endsWith: "$endsWith",
                };
                if (operators[filterModel.items[0].operator]) {
                    filters = {
                        [filterModel.items[0].field]: {
                            [operators[filterModel.items[0].operator]]:
                            filterModel.items[0].value || "",
                        },
                    };
                }
            }
        } else {
            const operators = {
                isEmpty: "$null",
                isNotEmpty: "$notNull",
            };
            if (operators[filterModel.items[0].operator]) {
                filters = {
                    [filterModel.items[0].field]: {
                        [operators[filterModel.items[0].operator]]: true,
                    },
                };
            }
        }
    }

    const requestOptions = {
        method: "GET",
        headers: { ...authAdminHeader(), "Content-Type": "application/json" },
    };

    const query = qs.stringify(
        {
            sort: [`${sortColumn}:${sort}`],
            filters: {
                enabled: true,
                ...filters,
            },
            pagination: {
                page: Number(page) + 1,
                pageSize,
            },
        },
        {
            encodeValuesOnly: true, // prettify URL
        }
    );

    return fetch(
        `${process.env.REACT_APP_API_URL}/end-users?${query}`,
        requestOptions
    ).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/${id}`, requestOptions).then(handleResponse);
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${process.env.REACT_APP_API_URL}/clients/register`, requestOptions).then(handleResponse);
}

function update(user) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/${user.id}`, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/${id}`, requestOptions).then(handleResponse);
}

function googleAuthInit() {
    const requestOptions = {
        method: 'GET',
    };

    const baseUrl = process.env.REACT_APP_API_URL.slice(0, process.env.REACT_APP_API_URL.lastIndexOf("/"))

    return fetch(`${baseUrl}/strapi-google-auth/init`, requestOptions).then(handleResponse);
}

function googleAuthWithCode(code) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json' },
        body: JSON.stringify({code})
    };

    const baseUrl = process.env.REACT_APP_API_URL.slice(0, process.env.REACT_APP_API_URL.lastIndexOf("/"))

    return fetch(`${baseUrl}/strapi-google-auth/user-profile`, requestOptions)
    .then(handleResponse)
    .then(data => {
        console.log('data', data)
        let {token, user} = data.data
        
        user = {
            jwt: token,
            user: user
        }
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));

        return user;
    });;
}

async function collectibleImageUpload(file, uploadConfig) {
    // console.log('file', file)
    const formData = new FormData()
    formData.append("files", file)
  
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/utils/collectibleImageUpload`,
        formData,
        {
          ...uploadConfig,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      )
      .then((result) => {
        return result.data
      })
}
