import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import App from './App';
import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";

import ThemeComponent from "./@core/theme/ThemeComponent";
import {
  SettingsConsumer,
  SettingsProvider,
} from "./@core/context/settingsContext";

import { store } from "./_helpers";
import { App } from "./App";
import { configureFakeBackend } from "./_helpers";

import * as Sentry from "@sentry/react";

// setup fake backend
configureFakeBackend();

Sentry.init({
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || 'development',
  dsn: "https://581ada76c80a4aba3563333bd6b1ae58@o4503962513047552.ingest.sentry.io/4505859174629376",
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/api-staging\.1kin\.io\/v1/,
        /^https:\/\/api\.1kin\.io\/v1/,
      ],
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <SettingsProvider>
        <SettingsConsumer>
          {({ settings }) => {
            return (
              <ThemeComponent settings={settings}>
                <App />
              </ThemeComponent>
            );
          }}
        </SettingsConsumer>
      </SettingsProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
