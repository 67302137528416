import { authAdminHeader } from "../_helpers";
import qs from "qs";
import moment from "moment";

export const waitlistService = {
  getAll,
  approve,
};

function getAll(filterModel, sort, sortColumn, page, pageSize) {
  let filters = {};
  if (filterModel && filterModel.items && filterModel.items.length) {
    if (filterModel.items[0].field === "email_verified") {
      if (filterModel.items[0].operator === "is") {
        if (filterModel.items[0].value === "true") {
          filters = { email_verified: { $eq: true } };
        } else if (filterModel.items[0].value === "false") {
          filters = {
            $or: [
              { email_verified: { $eq: false } },
              { email_verified: { $null: true } },
            ],
          };
        } else {
          filters = {};
        }
      }
    } else if (filterModel.items[0].value) {
      if (filterModel.items[0].field === "createdAt") {
        const currentDateStart = moment(filterModel.items[0].value)
          .startOf("day")
          .toDate()
          .toISOString();
        const currentDateEnd = moment(filterModel.items[0].value)
          .endOf("day")
          .toDate()
          .toISOString();

        switch (filterModel.items[0].operator) {
          case "is":
            filters = {
              createdAt: { $gte: currentDateStart, $lte: currentDateEnd },
            };
            break;
          case "after":
            filters = { createdAt: { $gte: currentDateEnd } };
            break;
          case "onOrAfter":
            filters = { createdAt: { $gte: currentDateStart } };
            break;
          case "before":
            filters = { createdAt: { $lte: currentDateStart } };
            break;
          case "onOrBefore":
            filters = { createdAt: { $lte: currentDateEnd } };
            break;
          default:
            break;
        }
      } else {
        const operators = {
          "=": "$eq",
          "!=": "$ne",
          ">": "$gt",
          ">=": "$gte",
          "<": "$lt",
          "<=": "$lte",
          contains: "$containsi",
          equals: "$eqi",
          startsWith: "$startsWith",
          endsWith: "$endsWith",
        };
        if (operators[filterModel.items[0].operator]) {
          filters = {
            [filterModel.items[0].field]: {
              [operators[filterModel.items[0].operator]]:
                filterModel.items[0].value || "",
            },
          };
        }
      }
    } else {
      const operators = {
        isEmpty: "$null",
        isNotEmpty: "$notNull",
      };
      if (operators[filterModel.items[0].operator]) {
        filters = {
          [filterModel.items[0].field]: {
            [operators[filterModel.items[0].operator]]: true,
          },
        };
      }
    }
  }

  const requestOptions = {
    method: "GET",
    headers: { ...authAdminHeader(), "Content-Type": "application/json" },
  };

  const query = qs.stringify(
    {
      sort: [`${sortColumn}:${sort}`],
      filters,
      pagination: {
        page: Number(page) + 1,
        pageSize,
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );

  return fetch(
    `${process.env.REACT_APP_API_URL}/end-users/getWaitlist?${query}`,
    requestOptions
  ).then(handleResponse);
}

function approve(selectedIds) {
  const requestOptions = {
    method: "POST",
    headers: { ...authAdminHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ userIds: selectedIds }),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/end-users/approve`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401 || response.status === 400) {
        // auto logout if 401 response returned from api
        // logout();
        // window.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
