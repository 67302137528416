import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import toast from "react-hot-toast";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Paper,
  Box,
} from "@mui/material";
import StatusLabel from "src/_components/StatusLabel";
import {
  SuccessButton,
  RejectButton,
  StyledTableRow,
  StyledTableCell,
} from "src/_components/PageElements";
import { collectibleService } from "../_services";
import Paginator from "src/_components/Paginator";
import EmptyState from "src/_components/EmptyState";

function AllCollectibles() {
  const [collectibles, setCollectibles] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    pageCount: 0,
    total: 0,
  });

  useEffect(() => {
    fetchCollectibles(1);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      searchCollectibles(searchStr);
    }, 300);

    return () => clearTimeout(debounceTimeout);
  }, [searchStr]); // eslint-disable-line react-hooks/exhaustive-deps


  const handleDynamicSearch = (e) => {
    const keyword = e.target.value;
    setSearchStr(keyword);
  };

  const searchCollectibles =async (str)=>{
    let collectibles = await collectibleService.getAll(
      str,
      pagination.pageSize,
      pagination.page,
    );
    console.log("collectibles", collectibles);
    setPagination(collectibles.meta.pagination);
    setCollectibles(
      collectibles.data.map((o) => {
        return {
          id: o.id,
          ...o.attributes,
        };
      })
    );
  }

  const fetchCollectibles = async (page, pageSize) => {
    let collectibles = await collectibleService.getAll(
      document.getElementById("searchText").value,
      pageSize || pagination.pageSize,
      page
    );
    console.log("collectibles", collectibles);
    setPagination(collectibles.meta.pagination);
    setCollectibles(
      collectibles.data.map((o) => {
        return {
          id: o.id,
          ...o.attributes,
        };
      })
    );
  };

  const handleStatus = async (collectibleId, status) => {
    await collectibleService.update({ id: collectibleId, status });
    toast.success(`Successfully updated`, {
      duration: 5000,
    });

    fetchCollectibles(pagination.page);
  };

  const onSelectPage = (page) => {
    setPagination({
      ...pagination,
      page,
    });

    fetchCollectibles(page);
  };
  const onSizeChange = (pageSize) => {
    setPagination({
      ...pagination,
      pageSize,
    });

    fetchCollectibles(pagination.page, pageSize);
  };

  const getAvailableNextStatus = (status) => {
    switch (status) {
      case "Pending":
        return ["Approved", "Rejected"];
      case "Approved":
        return ["Deleted"];
      case "Rejected":
        return ["Deleted"];
      case "Live":
        return ["Paused", "Deleted"];
      case "Paused":
        return ["Live", "Deleted"];
      case "Archived":
        return ["Deleted"];
      case "Deleted":
        return [];
      default:
        return ["Pending", "Deleted"];
    }
  };

  const getStatusButtonColor = (status) => {
    switch (status) {
      case "Pending":
        return "primary";
      case "Approved":
        return "info";
      case "Rejected":
        return "error";
      case "Live":
        return "success";
      case "Paused":
        return "warning";
      case "Archived":
        return "secondary";
      case "Deleted":
        return "error";
      default:
        return "success";
    }
  };

  const getActionText = (status) => {
    switch (status) {
      case "Pending":
        return "Pending";
      case "Approved":
        return "Approve";
      case "Rejected":
        return "Reject";
      case "Paused":
        return "Pause";
      case "Deleted":
        return "Delete";
      default:
        return "";
    }
  };

  return (
    <Container className="vh-100 overflow-auto pt-5" sx={{ paddingBottom: 20 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5" style={{ flex: 1 }}>
          Collectibles
        </Typography>
        <TextField
          className="form-control"
          placeholder="Search Title"
          onChange={handleDynamicSearch}
          id="searchText"
          variant="outlined"
          size="small"
        />
      </Box>
      <hr />
      <Paper sx={{ overflow: "auto" }}>
        {
          collectibles.length === 0 ?
          <EmptyState/>:
          <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell className="has-right-border"></StyledTableCell>
              <StyledTableCell className="has-right-border">
                Title
              </StyledTableCell>
              <StyledTableCell className="has-right-border">ID</StyledTableCell>
              <StyledTableCell className="has-right-border">
                Total Count
              </StyledTableCell>
              <StyledTableCell className="has-right-border">
                Start Date
              </StyledTableCell>
              <StyledTableCell className="has-right-border">
                End Date
              </StyledTableCell>
              <StyledTableCell className="has-right-border">
                Status
              </StyledTableCell>
              <StyledTableCell className="has-right-border">
                Created At
              </StyledTableCell>
              <StyledTableCell className="has-right-border">
                Action
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {collectibles.map((item) => (
              <TableRow key={item.id}>
                <TableCell sx={{ padding: 0 }}>
                  {item.assets?.thumbnailImage?.defaultMedia.src1x ? (
                    <img
                      src={item.assets?.thumbnailImage?.defaultMedia.src1x}
                      style={{ height: 30, width: 70, objectFit: "contain" }}
                    />
                  ) : (
                    <></>
                  )}
                </TableCell>
                <TableCell sx={{ width: 160 }}>
                  <Link
                    to={`collectibles/${item.id}`}
                    style={{ color: "white" }}
                  >
                    {item.title}
                  </Link>
                </TableCell>
                <TableCell>{item.collectible_uid}</TableCell>
                <TableCell>{item.total_count || "Unlimited"}</TableCell>
                <TableCell>{item.start_date}</TableCell>
                <TableCell>{item.end_date}</TableCell>
                <TableCell>
                  <StatusLabel value={item.status} />
                </TableCell>
                <TableCell>
                  {moment(item.createdAt).format("YYYY-MM-DD")}
                </TableCell>
                <TableCell>
                  {getAvailableNextStatus(item.status).map((status) =>
                    status === "Approved" ? (
                      <SuccessButton
                        key={status}
                        variant="contained"
                        color={getStatusButtonColor(status)}
                        size="small"
                        onClick={() => handleStatus(item.id, status)}
                        style={{ marginRight: 8 }}
                      >
                        {getActionText(status)}
                      </SuccessButton>
                    ) : (
                      <RejectButton
                        key={status}
                        variant="contained"
                        color={getStatusButtonColor(status)}
                        size="small"
                        onClick={() => handleStatus(item.id, status)}
                        style={{ marginRight: 8 }}
                      >
                        {getActionText(status)}
                      </RejectButton>
                    )
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        }
     
      </Paper>
      {/* <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 10,
            }}
          >
            {pagination.total > 0 && (
              <MuiPagination
                count={pagination.pageCount}
                page={pagination.page}
                onChange={(e, page) => onSelectPage(page)}
                variant="outlined"
                shape="rounded"
              />
            )}
          </div> */}
      {pagination.total > 0 && (
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Box sx={{ margin: 10 }}>
            <Paginator
              count={pagination.pageCount}
              totalCount={pagination.total}
              pageSize={pagination.pageSize}
              currentPage={pagination.page}
              onPageSizeChange={(pageSize) => {
                onSizeChange(pageSize);
              }}
              onChange={(page) => {
                onSelectPage(page);
              }}
            />
          </Box>
        </Box>
      )}
      {/* {collectibles.length === 0 && <div>No collectible</div>} */}
    </Container>
  );
}

export { AllCollectibles };
