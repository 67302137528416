import React, { useState } from 'react'
import {useDropzone} from 'react-dropzone'
import { userService } from '../_services';
import { Line } from 'rc-progress';
import { Button } from '@mui/material';

function FileUploader({onChangeFile, value}) {
    const [percent, setPercent] = useState(0)
    const {getRootProps, getInputProps} = useDropzone({    
    maxFiles:1,
    multiple: false,
    accept: {
        'image/*': [],
    },
    onDrop: async acceptedFiles => {
        const uploadConfig = {
            onUploadProgress: progressEvent => {
              var percent = Math.round(progressEvent.loaded * 100 / progressEvent.total);
              setPercent(Math.min(100, percent))
            }
        }; 
        if(acceptedFiles.length > 0) {
            let ret = await userService.upload(acceptedFiles[0], uploadConfig)
            console.log("ret", ret)
            let files =ret.map(o=>o.url)
            onChangeFile(files[0])
        }
    }
  })

  const deleteFile = (e)=>{
    if(e) {
        e.stopPropagation()
        e.preventDefault()
    }
    onChangeFile(undefined)
    setPercent(0)
  }

  return (
    <div>
        {!value && <div {...getRootProps({ className: 'dropzone' })} style={{border: '1px solid #ced4da', padding: 20, color: 'lightgrey', borderRadius: 4, textAlign: 'center'}}>
            <input {...getInputProps()} />
            <p>Drag & drop file here, or click to select file</p>
        </div>}
        {!value && percent > 0 && <Line percent={percent} strokeWidth='1' strokeColor='#2db7f5' strokeLinecap='square' />}
        {value && <div style={{display:'flex'}}>
            <img src={value} style={{width: 200}} alt=""/>
            <Button variant="contained" color="error" onClick={deleteFile} style={{height: 36, marginLeft: 10}}>Delete</Button>
        </div>}
    </div>
  )
}

export {FileUploader}
