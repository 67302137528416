import { styled } from "@mui/material/styles";
import { Button, TableCell, TableRow } from "@mui/material";

export const SuccessButton = styled(Button)`
  background-color: transparent;
  &&& {
    color: #69ca2b;
    border-color: #69ca2b;
  }
  border: 1px solid;
  margin: 5px 7px;
  font-size: 10px;
`;

export const InactiveButton = styled(Button)`
  background-color: transparent;
  border: 1px solid;
  &&& {
    border-color: #fdb529;
    color: #fdb529;
  }
  margin: 5px 7px;
  font-size: 10px;
`;

export const RejectButton = styled(Button)`
  background-color: transparent;
  &&& {
    border-color: #ff4d49;
    color: #ff4d49;
  }
  border: 1px solid;
  margin: 5px 7px;
  font-size: 10px;
`;

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  padding: "0 5px",
  backgroundColor: "#3a3e5b",
  ".has-right-border": {
    position: "relative",
  },
  ".has-right-border::before": {
    content: '""',
    position: "absolute",
    top: "25%",
    right: "5px",
    width: "2px",
    height: "50%",
    backgroundColor: "#4c506d",
  },
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  position: "relative",
  fontSize: "12px !important",
}));
