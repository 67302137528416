import { authAdminHeader } from "../_helpers"
import { handleResponse } from "./middleware"
import qs from "qs"

export const gameService = {
  register,
  getAll,
  getById,
  update,
  delete: _delete,
}

function getAll(q, pageSize, page) {
  const requestOptions = {
    method: "GET",
    headers: { ...authAdminHeader(), "Content-Type": "application/json" },
  }

  const query = qs.stringify(
    {
      sort: ["createdAt:desc"],
      filters: {
        title: {
          $containsi: q || "",
        },
        deleted: {
          $ne: true,
        },
      },
      pagination: {
        page,
        pageSize,
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  )

  return fetch(
    `${process.env.REACT_APP_API_URL}/games?${query}`,
    requestOptions
  ).then(handleResponse)
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authAdminHeader(), "Content-Type": "application/json" },
  }

  return fetch(
    `${process.env.REACT_APP_API_URL}/games/${id}`,
    requestOptions
  ).then(handleResponse)
}

function register(game) {
  const requestOptions = {
    method: "POST",
    headers: { ...authAdminHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(game),
  }

  return fetch(`${process.env.REACT_APP_API_URL}/games`, requestOptions).then(
    handleResponse
  )
}

function update(game) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authAdminHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ data: game }),
  }

  return fetch(
    `${process.env.REACT_APP_API_URL}/games/${game.id}`,
    requestOptions
  ).then(handleResponse)
}

function _delete(gameId) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authAdminHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({}),
  }

  return fetch(
    `${process.env.REACT_APP_API_URL}/games/${gameId}`,
    requestOptions
  ).then(handleResponse)
}
