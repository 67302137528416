import { styled } from "@mui/material/styles";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

const Paginator = ({
  totalCount,
  pageSize,
  onPageSizeChange,
  onChange,
  count,
  currentPage,
}) => {
  const leftArrowOpacity = currentPage === 1 ? 0.5 : 1;
  const rightArrowOpacity = currentPage === count ? 0.5 : 1;
  const leftCursor = currentPage === 1 ? "no-drop" : "pointer";
  const rightCursor = currentPage === count ? "no-drop" : "pointer";

  return (
    <Container>
      <Narration>Rows per page: </Narration>
      <Select
        id="paginator"
        onChange={(e) => {
          onPageSizeChange(e.target.value);
        }}
      >
        <Option value={10}>10</Option>
        <Option value={15}>15</Option>
        <Option value={20}>20</Option>
      </Select>
      <Label>
        {(currentPage - 1) * pageSize + 1}-{currentPage * pageSize} of{" "}
        {totalCount}
      </Label>
      <KeyboardArrowLeft
        onClick={() => {
          onChange(currentPage - 1);
        }}
        sx={{ mr: 2, opacity: leftArrowOpacity, cursor: leftCursor }}
      />
      <KeyboardArrowRight
        onClick={() => {
          onChange(currentPage + 1);
        }}
        sx={{ mr: 2, opacity: rightArrowOpacity, cursor: rightCursor }}
      />
    </Container>
  );
};

export default Paginator;

export const Label = styled("div")(({ theme }) => ({
  display: "flex",
  margin: "0 20px",
}));

export const Option = styled("option")(({ theme }) => ({
  backgroundColor: "transparent",
  display: "flex",
  border: "none",
  outline: "none",
  color: "white",
}));

export const Select = styled("select")(({ theme }) => ({
  backgroundColor: "transparent",
  display: "flex",
  border: "none",
  outline: "none",
  color: "white",
  fontSize: "16px",
}));

export const Container = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  margin: "5px 10px",
}));

export const Narration = styled("div")(({ theme }) => ({
  display: "flex",
  marginRight: 5,
  fontSize: 14,
}));
