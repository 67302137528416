import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, TextField, Typography, Divider, Grid } from '@mui/material';

import { userActions } from '../_actions';
import { userService } from '../_services';

const logo = require('../assets/1k-logo.svg').default
const googleLogo = require('../assets/google.png')

function LoginPage() {
    const [inputs, setInputs] = useState({
        username: '',
        password: ''
    });
    const [submitted, setSubmitted] = useState(false);
    const { username, password } = inputs;
    const loggingIn = useSelector(state => state.authentication.loggingIn);
    const dispatch = useDispatch();
    const location = useLocation();

    // reset login status
    useEffect(() => { 
        dispatch(userActions.logout()); 
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function handleSubmit(e) {
        e.preventDefault();

        setSubmitted(true);
        if (username && password) {
            // get return url from location state or default to home page
            const { from } = location.state || { from: { pathname: "/" } };
            dispatch(userActions.login(username, password, from));
        }
    }

    async function googleLogin() {
        let data = await userService.googleAuthInit()
        console.log('data', data)
        if(data && data.url) {
            window.location.href=data.url
            console.log('here')
        }
    }

    return (
        <Container
            sx={{
                height: '100vh',
                // backgroundColor: 'rgb(48, 51, 78)',
                color: 'white',
                padding: '100px 0',
            }}
        >
            <Grid container justifyContent="center">
                <Grid item xs={12} sm={10} md={8} lg={6}>
                    <img src={logo} style={{ margin: '0 auto', display: 'block' }} alt="Logo" />
                    <Typography variant="h4" align="center" gutterBottom sx={{mb: 10, mt: 5}}>
                        BP Admin Login
                    </Typography>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={googleLogin}
                        sx={{ mb: 3 }}
                        startIcon={
                            loggingIn && (
                                <span className="spinner-border spinner-border-sm mr-1"></span>
                            )
                        }
                    >
                        <img src={googleLogo} style={{ width: 30, marginRight: 10 }} alt="Google Logo" />
                        Login with Google
                    </Button>
                    <Divider variant="middle" sx={{ mb: 3 }} />
                    <form name="form" onSubmit={handleSubmit}>
                        <TextField
                            fullWidth
                            label="Username or Email"
                            name="username"
                            value={username}
                            onChange={handleChange}
                            variant="outlined"
                            error={submitted && !username}
                            helperText={submitted && !username && 'Username is required'}
                            sx={{ mb: 3 }}
                        />
                        <TextField
                            fullWidth
                            label="Password"
                            type="password"
                            name="password"
                            value={password}
                            onChange={handleChange}
                            variant="outlined"
                            error={submitted && !password}
                            helperText={submitted && !password && 'Password is required'}
                            sx={{ mb: 3 }}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            startIcon={
                                loggingIn && (
                                    <span className="spinner-border spinner-border-sm mr-1"></span>
                                )
                            }
                        >
                            Login with email
                        </Button>
                    </form>
                </Grid>
            </Grid>
        </Container>
    );
}

export { LoginPage };
