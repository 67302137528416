import { authAdminHeader } from "../_helpers"
import qs from "qs"
import { handleResponse } from "./middleware"

export const collectibleService = {
  register,
  getAll,
  getById,
  update,
  getCodes,
  delete: _delete,
}

function getAll(q, pageSize, page) {
  const requestOptions = {
    method: "GET",
    headers: { ...authAdminHeader(), "Content-Type": "application/json" },
  }

  const query = qs.stringify(
    {
      sort: ["createdAt:desc"],
      filters: {
        title: {
          $containsi: q || "",
        },
      },
      pagination: {
        page,
        pageSize,
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  )

  return fetch(
    `${process.env.REACT_APP_API_URL}/collectibles?${query}`,
    requestOptions
  ).then(handleResponse)
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authAdminHeader(), "Content-Type": "application/json" },
  }

  return fetch(
    `${process.env.REACT_APP_API_URL}/collectibles/${id}`,
    requestOptions
  ).then(handleResponse)
}

function getCodes(id) {
  const requestOptions = {
    method: "POST",
    headers: { ...authAdminHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ collectibleId: id }),
  }

  return fetch(
    `${process.env.REACT_APP_API_URL}/claim-codes/getClaimCodesOfCollectible`,
    requestOptions
  ).then(handleResponse)
}

function register(collectible) {
  const requestOptions = {
    method: "POST",
    headers: { ...authAdminHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(collectible),
  }

  return fetch(
    `${process.env.REACT_APP_API_URL}/collectibles`,
    requestOptions
  ).then(handleResponse)
}

function update(collectible) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authAdminHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ data: collectible }),
  }

  return fetch(
    `${process.env.REACT_APP_API_URL}/collectibles/${collectible.id}`,
    requestOptions
  ).then(handleResponse)
}

function _delete(collectibleId) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authAdminHeader(), "Content-Type": "application/json" },
    body: "",
  }

  return fetch(
    `${process.env.REACT_APP_API_URL}/collectibles/${collectibleId}`,
    requestOptions
  ).then(handleResponse)
}
