import React, { useState } from "react";
import { Route, Redirect, Link } from "react-router-dom";

import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {
  VideogameAsset,
  PersonOutline,
  Collections,
  GroupAdd,
} from "@mui/icons-material";

import themeConfig from "src/configs/themeConfig";
import { useSettings } from "src/@core/hooks/useSettings";
import Drawer from "src/@core/layouts/components/vertical/navigation/Drawer";
import AppBar from "../AppBar";

const logo = require("../assets/1k-logo.svg").default;

const VerticalLayoutWrapper = styled("div")({
  height: "100%",
  display: "flex",
});

const MainContentWrapper = styled(Box)({
  flexGrow: 1,
  minWidth: 0,
  display: "flex",
  flexDirection: "column",
});

const ContentWrapper = styled("main")(({ theme }) => ({
  flexGrow: 1,
  width: "100%",
  padding: theme.spacing(6),
  transition: "padding .25s ease-in-out",
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

const StyledLink = styled(Link)({
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
});

const Navigation = (props) => {
  return (
    <Drawer {...props}>
      <Box sx={{ py: 2, px: 5 }}>
        <StyledLink to="/">
          <img width={48} height={48} alt={`logo`} src={logo} />
        </StyledLink>
      </Box>
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          px: 2,
          pt: 4,
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <List className="nav-items" sx={{ pt: 0 }}>
          <ListItem disablePadding className="nav-link" sx={{ mt: 1.5 }}>
            <Button
              variant="link"
              fullWidth
              href="/clients"
              sx={{ justifyContent: "start", textTransform: "capitalize" }}
            >
              <PersonOutline sx={{ mr: 2 }} />
              BP Users
            </Button>
          </ListItem>
          <ListItem disablePadding className="nav-link" sx={{ mt: 1.5 }}>
            <Button
              variant="link"
              fullWidth
              href="/waitlist"
              sx={{ justifyContent: "start", textTransform: "capitalize" }}
            >
              <GroupAdd sx={{ mr: 2 }} />
              Waitlist
            </Button>
          </ListItem>
          <ListItem disablePadding className="nav-link" sx={{ mt: 1.5 }}>
            <Button
              variant="link"
              fullWidth
              href="/users"
              sx={{ justifyContent: "start", textTransform: "capitalize" }}
            >
              <GroupAdd sx={{ mr: 2 }} />
              End Users
            </Button>
          </ListItem>
          <ListItem disablePadding className="nav-link" sx={{ mt: 1.5 }}>
            <Button
              variant="link"
              fullWidth
              href="/games"
              sx={{ justifyContent: "start", textTransform: "capitalize" }}
            >
              <VideogameAsset sx={{ mr: 2 }} />
              Games
            </Button>
          </ListItem>
          <ListItem disablePadding className="nav-link" sx={{ mt: 1.5 }}>
            <Button
              variant="link"
              fullWidth
              href="/collectibles"
              sx={{ justifyContent: "start", textTransform: "capitalize" }}
            >
              <Collections sx={{ mr: 2 }} />
              Collectibles
            </Button>
          </ListItem>
        </List>

        <Box
          sx={{
            position: "relative",
            overflow: "hidden",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
          }}
        >
        </Box>
      </Box>
    </Drawer>
  );
};

function MenuLayout({ children }) {
  const { settings } = useSettings();

  const { skin, contentWidth } = settings;
  const { navigationSize, collapsedNavigationSize } = themeConfig;

  const navWidth = navigationSize;
  const navigationBorderWidth = skin === "bordered" ? 1 : 0;
  const collapsedNavWidth = collapsedNavigationSize;

  // ** States
  const [navHover, setNavHover] = useState(false);
  const [navVisible, setNavVisible] = useState(false);
  const hidden = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  // ** Toggle Functions
  const toggleNavVisibility = () => setNavVisible(!navVisible);

  const mediaSx = {
    mx: "auto",
    "@media (min-width:1440px)": { maxWidth: 1440 },
    "@media (min-width:1200px)": { maxWidth: "100%" },
  };

  return (
    <>
      <VerticalLayoutWrapper className="layout-wrapper">
        <Navigation
          hidden={hidden}
          navWidth={navWidth}
          navHover={navHover}
          navVisible={navVisible}
          setNavHover={setNavHover}
          setNavVisible={setNavVisible}
          collapsedNavWidth={collapsedNavWidth}
          toggleNavVisibility={toggleNavVisibility}
          navigationBorderWidth={navigationBorderWidth}
          settings={settings}
        />
        <MainContentWrapper className="layout-content-wrapper">
          <AppBar
            toggleNavVisibility={toggleNavVisibility}
            settings={settings}
          />

          <ContentWrapper
            className="layout-page-content"
            sx={{
              ...(contentWidth === "boxed" && mediaSx),
            }}
          >
            {children}
          </ContentWrapper>
        </MainContentWrapper>
      </VerticalLayoutWrapper>
    </>
  );
}

function PrivateRoute({ component: Component, roles, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!localStorage.getItem("user")) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }

        // logged in so return component
        return (
          <MenuLayout>
            <Component {...props} />
          </MenuLayout>
        );
      }}
    />
  );
}

export { PrivateRoute };
