import React from "react";
import { Box, Button, TextField, Typography, styled } from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

const StyledGridToolbarContainer = styled(GridToolbarContainer)({
  paddingLeft: "0px !important",
  paddingRight: "0px !important",
  paddingBottom: "16px",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "start",
  gap: 8,
});

export const Toolbar = ({ total, qty, setQty, selectUsers }) => {
  return (
    <StyledGridToolbarContainer>
      <Box>
        <GridToolbarColumnsButton
          color="primary"
          variant="outlined"
          sx={{ height: "40px" }}
        />
      </Box>
      <Box>
        <GridToolbarFilterButton
          color="primary"
          variant="outlined"
          sx={{ height: "40px" }}
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", px: 3 }}>
        <Typography
          variant="body1"
          sx={{ fontWeight: 600, color: "text.disabled" }}
        >
          Total Results:
        </Typography>
        <Typography
          variant="body1"
          sx={{ ml: 3, fontWeight: 600, color: "text.secondary" }}
        >
          {total}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", px: 3 }}>
        <Typography
          variant="body1"
          sx={{ fontWeight: 600, color: "text.disabled" }}
        >
          Select Top Results:
        </Typography>
        <TextField
          label="Qty"
          type="number"
          variant="outlined"
          value={qty}
          size="small"
          onChange={(e) => setQty(Number(e.target.value))}
          sx={{ ml: 3, width: "120px" }}
        />
        <Button
          color="primary"
          variant="outlined"
          sx={{ height: "40px", ml: 2 }}
          onClick={selectUsers}
        >
          Select
        </Button>
      </Box>
    </StyledGridToolbarContainer>
  );
};
