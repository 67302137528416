import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Paper,
  Box,
} from "@mui/material"
import {
  SuccessButton,
  RejectButton,
  StyledTableRow,
  StyledTableCell,
  InactiveButton,
} from "src/_components/PageElements"
import { gameService } from "../_services"
import moment from "moment"
import Paginator from "src/_components/Paginator"
import toast from "react-hot-toast"
import EmptyState from "src/_components/EmptyState"

function AllGames() {
  
  const [games, setGames] = useState([])
  const [searchStr, setSearchStr] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    pageCount: 0,
    total: 0,
  })

  useEffect(() => {
    fetchGames(1)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      searchGames(searchStr);
    }, 300);

    return () => clearTimeout(debounceTimeout);
  }, [searchStr]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDynamicSearch = (e) => {
    const keyword = e.target.value;
    setSearchStr(keyword);
  };

  const searchGames =async (str)=>{
    let games = await gameService.getAll(
      str,
      pagination.pageSize,
      pagination.page
    )
    console.log("games", games)
    setPagination(games.meta.pagination)
    setGames(
      games.data.map((o) => {
        return {
          id: o.id,
          ...o.attributes,
        }
      })
    )
  }

  const fetchGames = async (page, pageSize) => {
    let games = await gameService.getAll(
      document.getElementById("searchText").value,
      pageSize || pagination.pageSize,
      page
    )
    console.log("games", games)
    setPagination(games.meta.pagination)
    setGames(
      games.data.map((o) => {
        return {
          id: o.id,
          ...o.attributes,
        }
      })
    )
  }

  const handleActive = async (gameId) => {
    await gameService.update({ id: gameId, disabled: false })
    toast.success(`Successfully activated`, {
      duration: 5000,
    })
    fetchGames(pagination.page)
  }

  const handleInactive = async (gameId) => {
    await gameService.update({ id: gameId, disabled: true })
    toast.success(`Successfully inactivated`, {
      duration: 5000,
    })

    fetchGames(pagination.page)
  }

  const handleDelete = async (gameId) => {
    await gameService.update({ id: gameId, deleted: true })
    toast.success(`Successfully deleted`, {
      duration: 5000,
    })
    fetchGames(pagination.page)
  }

  const onSelectPage = (page) => {
    setPagination({
      ...pagination,
      page,
    })

    fetchGames(page)
  }

  const onSizeChange = (pageSize) => {
    setPagination({
      ...pagination,
      pageSize,
    })

    fetchGames(pagination.page, pageSize)
  }

  return (
    <Container className="vh-100 overflow-auto pt-5" sx={{ pb: 5 }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5" style={{ flex: 1 }}>
          Games
        </Typography>
        <div style={{ flex: 1 }}>
          <TextField
            className="form-control"
            placeholder="Search Title"
            onChange={handleDynamicSearch}
            id="searchText"
            variant="outlined"
            size="small"
          />
        </div>
      </div>
      <hr />
      <Paper>
        {games.length === 0 ? (
          <EmptyState />
        ) : (
          <Table>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell className="has-right-border"></StyledTableCell>
                <StyledTableCell className="has-right-border">
                  Title
                </StyledTableCell>
                <StyledTableCell className="has-right-border">
                  Publisher
                </StyledTableCell>
                <StyledTableCell className="has-right-border">
                  Release Date
                </StyledTableCell>
                <StyledTableCell className="has-right-border">
                  Status
                </StyledTableCell>
                <StyledTableCell className="has-right-border">
                  Created At
                </StyledTableCell>
                <StyledTableCell className="has-right-border">
                  Action
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {games.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    {item.cover_art?.defaultMedia.src1x ? (
                      <img
                        src={item.cover_art?.defaultMedia.src1x}
                        alt={item.cover_art?.alt}
                        style={{ height: 50, width: 100, objectFit: "contain" }}
                      />
                    ) : (
                      <></>
                    )}
                  </TableCell>
                  <TableCell>
                    <Link to={`games/${item.id}`} style={{ color: "white" }}>
                      {item.title}
                    </Link>
                  </TableCell>
                  <TableCell>{item.publisher}</TableCell>
                  <TableCell>{item.release_date}</TableCell>
                  <TableCell>{item.disabled ? "Inactive" : "Active"}</TableCell>
                  <TableCell>
                    {moment(item.createdAt).format("YYYY-MM-DD")}
                  </TableCell>
                  <TableCell>
                    {item.disabled ? (
                      <SuccessButton
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={() => handleActive(item.id)}
                      >
                        Active
                      </SuccessButton>
                    ) : (
                      <InactiveButton
                        variant="contained"
                        color="warning"
                        size="small"
                        onClick={() => handleInactive(item.id)}
                      >
                        Inactive
                      </InactiveButton>
                    )}
                    <RejectButton
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={() => handleDelete(item.id)}
                    >
                      Delete
                    </RejectButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Paper>
      {pagination.total > 0 && (
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Box sx={{ margin: 10 }}>
            <Paginator
              count={pagination.pageCount}
              totalCount={pagination.total}
              pageSize={pagination.pageSize}
              currentPage={pagination.page}
              onPageSizeChange={(pageSize) => {
                onSizeChange(pageSize)
              }}
              onChange={(page) => {
                onSelectPage(page)
              }}
            />
          </Box>
        </Box>
      )}
      {/* {games.length === 0 && <div>No game</div>} */}
    </Container>
  )
}

export { AllGames }
