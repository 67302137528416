import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { userService } from "../_services";
import { Button, Box } from "@mui/material";
import { FormHelperText } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress';

function FeaturedImageUploader({ onChangeFile, value }) {
  const [percent, setPercent] = useState(0);
  const [errorMsg, setErrorMsg] = useState();
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    multiple: false,
    accept: {
      "image/*": [],
      // 'video/*': [],
    },
    onDrop: async (acceptedFiles) => {
      console.log("acceptedFiles", acceptedFiles);
      setErrorMsg('')
      const uploadConfig = {
        onUploadProgress: (progressEvent) => {
          var percent = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setPercent(Math.min(100, percent));
        },
      };
      if (acceptedFiles.length > 0) {
        let ret = await userService.collectibleImageUpload(acceptedFiles[0], uploadConfig);
        console.log('ret', ret)
        if(ret.error) {
          setErrorMsg(ret.error)
          return
        }
        let files = ret.result;
        onChangeFile(files);
      }
    },
  });

  const deleteFile = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    onChangeFile(undefined);
    setPercent(0);
    setErrorMsg('')
  };

  return (
    <div>
      {!value && (
        <div
          {...getRootProps({ className: "dropzone" })}
          style={{
            border: "1px solid #ced4da",
            padding: 20,
            color: "lightgrey",
            borderRadius: 4,
            textAlign: "center",
          }}
        >
          <input {...getInputProps()} />
          <p>Drag & drop a image here, or click to select file</p>
          <p style={{fontSize:12}}>Allowed: JPG, GIF or PNG. Max size of 5MB. Recommended dimensions: 2,800 x 1,200 pixels</p>
        </div>
      )}
      {!value && !errorMsg && percent > 0 && (
        <LinearProgress />
      )}
      {!value && errorMsg && <FormHelperText error={true} id="password-error">{errorMsg}</FormHelperText>}
      {value && (
        <Box sx={{ display: "flex" }}>
          <img src={value} style={{ width: 200 }} alt=""/>
          <Button
            color="error"
            onClick={deleteFile}
            style={{ height: 36, marginLeft: 10 }}
          >
            Delete
          </Button>
        </Box>
      )}
    </div>
  );
}

export { FeaturedImageUploader };
