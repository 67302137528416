const link = {
  MuiLink: {
    styleOverrides: {
      root: {
        textDecorationColor: 'transparent'
      }
    }
  }
}

export default link
